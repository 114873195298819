.footer {
    background: #2a2a32;
    color: rgba(255, 255, 255, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.03);
    line-height: 30px;

    .label {
        font-size: 24px;
        font-weight: 400;
        line-height: 60px;
        color: #fff;
    }



    .qr img {
        width: 80px;
        height: 80px;
    }

}
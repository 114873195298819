.list_head {
    .top {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);

        .desc {
            color: #8a8a8a;
        }
    }

    .bottom {
        background: #f5f5f5;
        padding: 22px 40px;

        .item {
            padding: 6px 24px;
            border-radius: 6px;
            margin-right: 20px;
            color: #5a5a5a;
            cursor: pointer;
        }

        .item:hover,
        .active {
            background: #346dc3;
            color: #fff;
        }


    }

}

.list_head_m {
    .block {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
    }

    .desc {
        color: #8a8a8a;
    }

    .bottom {
        margin: 10px 0;

        .item {
            padding: 2px 8px;
            background: #fff;
            color: #5a5a5a;
            border-radius: 6px;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .active {
            background: #346dc3;
            color: #fff;
        }
    }
}

.list_content {
    .item {
        cursor: pointer;

        .cover {
            width: 100%;
            margin-bottom: 16px;
        }

        background: #f5f5f5;

        .title {
            color: #323232;
        }

        .time {
            text-align: right;
            color: #b5b5b5;
            font-size: 12px;
        }
    }

}
@tailwind base;
@tailwind components;
@tailwind utilities;

div {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
}

:root {
    --animate-delay: 0.5s;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #2f2f31;
}

/* 滚动槽 */

::-webkit-scrollbar-track {

    -webkit-box-shadow: inset 0 0 6px rgb(8, 10, 14);

    border-radius: 10px;

    background: #021b49;

}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {

    border-radius: 10px;

    background: #0c0d0d;

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

}

::-webkit-scrollbar-thumb:window-inactive {

    background: #343639;

}

/* 边角 即两个滚动条的交汇处 */

::-webkit-scrollbar-corner {

    background: #3b3c3e;

    border-radius: 10px;

}
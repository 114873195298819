.pc_nav {
    li {
        font-size: 18px;
    }

    li:hover {
        color: #346dc3;

    }
}

.m_nav {
    color: #346dc3;
}



.pc_card {
    background: #fff;
    border-top: 1px solid #f5f5f5;

    .item {
        background: #F9FAFB;


        .icon {
            font-size: 28px;
            color: #346dc3;
            margin-bottom: 15px;
        }

        .title {
            font-size: 18px;
            color: #333;
            line-height: 30px;
        }

        .desc {

            font-size: 14px;
            color: #888;
            line-height: 20px;
        }
    }

    .item:hover {
        cursor: pointer;

        background: #346dc3;

        .icon {
            color: #fff;
        }

        .title {
            color: #fff;
        }

        .desc {
            color: #fff;
        }
    }
}




.m_item {
    font-size: 15px;
    color: #262626;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m_sub_wrap {
    background: #fafafa;
    padding: 25px;

    .m_sub_nav {
        text-align: center;
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;

        .icon {
            color: #346dc3;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}




/* 子菜单默认是隐藏状态 */
.sub-menu {

    position: absolute;
    top: 100%;
    left: 0;
    // width: 200px;
    /* 根据实际需求调整宽度 */
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    /* 防止内容在动画开始前可见 */
    max-height: 0;
    transition: max-height 0.8s ease, opacity 0.3s ease, visibility 0s linear 0.3s;
}

/* 当鼠标悬停在主菜单项上时，子菜单显示 */
.menu-item:hover .sub-menu {
    max-height: 300px;
    opacity: 1;
    visibility: visible;
    // transform: translateY(0);
    transition-delay: 0s;
}

.menu-item .sub-menu .item:hover {
    background-color: #346dc3;
    /* 背景变为蓝色 */
    color: white;
    /* 文字变为白色 */
}
.loading_b {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-smooth: auto;
    font-weight: 300;
    line-height: 1.5;
    color: #444;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 40;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
    background-color: #20293a;

    .loading {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        background-color: #20293a;
        color: #20293a;
        border-radius: 50%;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        animation: text 10s ease infinite;
    }

    .loading:after {
        position: absolute;
        content: "";
        top: -5%;
        left: -5%;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        transform: scale(0.85);
        filter: blur(5vw);
        background: linear-gradient(270deg, #00fcbd, #7400f9);
        background-size: 150% 150%;
        border-radius: 50%;
        animation: glowmation 10s linear infinite;
    }

    @keyframes glowmation {
        0% {
            top: -3%;
            left: -3%;
            background-position: 0% 50%;
            background-size: 150% 150%;
        }

        12.5% {
            top: -3%;
            left: 0;
            background-size: 70% 30%;
        }

        25% {
            top: -3%;
            left: 3%;
            background-size: 100% 50%;
        }

        37.5% {
            top: 0;
            left: 3%;
            background-size: 70% 30%;
        }

        50% {
            top: 3%;
            left: 3%;
            background-position: 100% 50%;
            background-size: 30% 30%;
        }

        62.5% {
            top: 3%;
            left: 0;
            background-size: 30% 70%;
        }

        75% {
            top: 3%;
            left: -3%;
            background-size: 50% 100%;
        }

        87.5% {
            top: 0;
            left: -3%;
            background-size: 30% 70%;
        }

        100% {
            top: -3%;
            left: -3%;
            background-position: 0% 50%;
            background-size: 150% 150%;
        }
    }

    @keyframes text {
        0% {
            color: #7400f9;
        }

        50% {
            color: #00fcbd;
        }

        100% {
            color: #7400f9;
        }
    }
}

.home_card1 {

    // background: #5b4f96;
    color: #fff;

    .content {
        .desc {
            color: rgba(255, 255, 255, 0.6);
        }

        .desc_b {
            height: 1px;
            background-color: rgba(255, 255, 255, 0.1);
        }

        .other {
            .value {
                margin-top: 20px;
                color: rgba(255, 255, 255, 0.8);
                line-height: 30px;
            }
        }
    }
}

.home_card2 {
    background: #eaeaea;
    color: #000;

    .content {
        .desc {
            color: #898989;
        }

        .desc_b {
            height: 1px;
            background-color: #d6d6d6;
            margin-top: 20px;
        }

        .other {
            .icon {
                color: #5c93b1;
            }

            .value {
                color: #888;
                line-height: 30px;
            }
        }
    }
}

.home_card3 {
    background: #fff;
    color: #000;

    .content {
        .desc {
            color: #898989;
        }

        .desc_b {
            height: 1px;
            background-color: #d6d6d6;
            margin-top: 20px;
        }

        .other {
            .icon {
                color: #5c93b1;
            }

            .value {
                color: #888;
                line-height: 30px;
            }
        }
    }
}
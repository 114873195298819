.wrap {
    position: relative;
    width: 100%;
    // height: 34vw;
    // min-height: 900px;

    .banner {
        width: 100%;
        // height: 32vw;

        .item {
            width: 100%;
            height: 100%;
        }

        .item img {
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }
    }

    .card {
        .nav {
            .icon {
                background: #f5f8fa;
                border-radius: 10px;

                .svg {
                    color: #5a5a5a;
                }
            }

            .icon_m {
                color: #ff6000;
            }

            .title_m {
                font-size: 13px;
                color: #8a8a8a;
            }

            .right {

                .title {
                    font-weight: 500;
                    color: #323232;
                }

                .desc {
                    color: rgba(25, 28, 61, 0.5);
                }
            }
        }

        .nav:hover {
            .icon {
                background: #ff6000;

                .svg {
                    color: #fff;
                }
            }

            .right {

                .title {
                    color: #ff6000;
                }
            }
        }
    }
}


.swiper-button-prev {
    width: 12px !important;
    height: 18px !important;
    left: 63vw !important;
    top: auto !important;
    right: auto !important;
    bottom: 2vw !important;
    padding: 10px 0;
}

.swiper-button-next {
    width: 12px !important;
    height: 18px !important;
    left: 64vw !important;
    top: auto !important;
    right: auto !important;
    bottom: 2vw !important;
    padding: 10px 0;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.swiper-pagination {
    padding: 8px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background: rgba(51, 51, 51, 0.3);
    left: 65vw !important;
    bottom: 2vw !important;
    width: auto !important;
}

.swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5) !important
}

.swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 8px !important;
    background: #fff !important;
}